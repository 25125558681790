<template>
    <div class="el-message-box__wrapper" ref="pop">
        <div class="el-message-box">
            <div class="el-message-box__header">
                <div class="el-message-box__title">{{ title }}</div>
                <button class="el-message-box__headerbtn" @click="close">
                    <i class="el-message-box__close el-icon-close"></i>
                </button>
            </div>
            <div class="el-message-box__content">
                <slot></slot>
            </div>
            <div class="el-message-box__btns">
                <button class="el-button el-button--default el-button--small el-button--primary" @click="close">Цуцлах</button>
                <button class="el-button el-button--default el-button--small el-button--primary"
                    @click="submitData">Мэдээж</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: '',
            default: 'Title'
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        const dom = this.$refs.pop
        document.body.appendChild(dom)
    },
    methods: {
        close() {
            this.$emit('update:visible', false)
        },
        submitData() {
            this.$emit('update')
        },
    }
}
</script>
<style lang="scss" scoped>
.el-message-box__wrapper {
    background: rgba(0, 0, 0, .5);
    z-index: 20;
}
</style>